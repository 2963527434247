
















































































































































































































import { Component, Vue } from 'vue-property-decorator'
import ListToolbar from '@/components/ListToolbar/index.vue'
import { deleteRole, getRoles, saveRole } from '@/api/roles'
import { getPrivileges } from '@/api/privileges'
import {
  capitalizeFirstLetter,
  errorMsg,
  successMsg
} from '@/utils'
import { AppModule, DeviceType } from '@/store/modules/app'

@Component({
  name: 'RoleList',
  components: {
    ListToolbar
  }
})

export default class extends Vue {
  private role: any = {}
  private deleteRoleId = null
  private roles: any = []
  private groups: any = {}
  private listLoading = false
  private newRoleDialog = false
  private deleteRoleDialog = false
  private privileges: any = {}
  private capitalizeFirstLetter = capitalizeFirstLetter
  private headers = [
    'VIEW',
    'READ',
    'READ_OTHERS',
    'CREATE',
    'EDIT',
    'EDIT_OTHERS',
    'DELETE',
    'DELETE_OTHERS',
    'LIST',
    'LIST_OTHERS',
    'APPROVE'
  ]

  private newRoleModel = {
    name: '',
    privileges: [],
    roleId: null
  }

  created() {
    this.listLoading = true
    this.getRoles().then(() => {
      this.role = this.roles[0]
    }).then(() => {
      return this.getPrivileges()
    }).finally(() => {
      this.listLoading = false
    })
  }

  get dialogSize() {
    if (AppModule.device === DeviceType.Desktop) {
      return '30%'
    }
    return '80%'
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  get isDesktop() {
    return AppModule.device === DeviceType.Desktop
  }

  private prepareBeforeSave() {
    this.role.privileges = []
    Object.keys(this.privileges).forEach((key: string) => {
      if (this.privileges[key].selected) {
        this.role.privileges.push({
          id: this.privileges[key].id,
          owner: this.privileges[key].owner,
          name: this.privileges[key].name
        })
      }
    })
  }

  private async saveRole() {
    try {
      this.prepareBeforeSave()
      await saveRole(this.role)
      await successMsg('roleList.saveSuccess')
    } catch (e) {
      await errorMsg('api.serverError')
    }
  }

  private async getRoles() {
    try {
      const { data } = await getRoles({
        query: {
          pagination: {
            fetch_all: true
          }
        },
        options: {
          with_privileges: true
        }
      })
      this.roles = data.collection || []
    } catch (e) {}
  }

  private async getPrivileges() {
    try {
      const { data } = await getPrivileges({
        query: {
          pagination: {
            fetch_all: true
          }
        }
      })
      const groups: any = {}
      const privileges: any = {}
      const active = (this.role.privileges || []).map((privilege: any) => {
        return privilege.id
      })
      data.collection.forEach((privilege: any) => {
        const otherIndex = privilege.name.indexOf("OTHERS_")
        const groupName = otherIndex > -1 ? privilege.name.substr(otherIndex+7) : privilege.name.substr(privilege.name.indexOf("_")+1)
        privilege.selected = active.includes(privilege.id)
        privileges[privilege.name] = privilege
        groups[groupName] = groups[groupName] || []
        groups[groupName].push(privilege)
      })
      this.groups = groups
      this.privileges = privileges
    } catch (e) {

    }
  }

  private toggleSelection(groupName: any, selected = false) {
    this.groups[groupName].forEach((privilege: any) => {
      this.privileges[privilege.name].selected = selected
    })
  }

  private closeDeleteRoleForm() {
    this.deleteRoleDialog = false
    this.deleteRoleId = null
  }

  private closeAddNewRoleForm() {
    this.newRoleDialog = false
    this.newRoleModel = {
      name: '',
      privileges: [],
      roleId: null
    }
  }

  private async addNewRoleForm() {
    if (!this.newRoleModel.name) {
      return errorMsg('roleList.nameRequired')
    }

    if (this.newRoleModel.roleId) {
      this.newRoleModel.privileges = this.roles.find((role: any) => {
        return role.id === this.newRoleModel.roleId
      }).privileges
    }

    try {
      await saveRole({
        name: this.newRoleModel.name,
        privileges: this.newRoleModel.privileges
      })
      await successMsg('roleList.saveSuccess')
      await this.getRoles()
      this.closeAddNewRoleForm()
    } catch (e) {
      await errorMsg('api.serverError')
    }
  }

  private handleCommand(command: string) {
    switch (command) {
      case 'save_role': {
        this.saveRole()
        break
      }
      case 'add_role': {
        this.newRoleDialog = true
        break
      }
      case 'delete_role': {
        this.deleteRoleDialog = true
        break
      }
      case 'select_all': {
        this.changeAllPrivileges(() => true)
        break
      }
      case 'deselect_all': {
        this.changeAllPrivileges(() => false)
        break
      }
    }
  }

  private async deleteRoleForm() {
    if (!this.deleteRoleId) {
      return successMsg('roleList.roleRequired')
    }
    try {
      await deleteRole({
        id: this.deleteRoleId
      })
      await successMsg('roleList.deleteSuccess')
      await this.getRoles()
      this.closeDeleteRoleForm()
    } catch (e) {
      await errorMsg('api.serverError')
    }
  }

  private changeAllPrivileges(callback: any) {
    Object.keys(this.privileges).forEach((key: string) => {
      this.privileges[key].selected = callback(this.privileges[key])
    })
  }

  private onSelectChange(item: any) {
    const active = item.privileges.map((privilege: any) => {
      return privilege.id
    })
    this.changeAllPrivileges((privilege: any) => {
      return active.includes(privilege.id)
    })
  }
}
