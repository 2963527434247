import request from '@/utils/request'

export const getPrivileges = (data: any) =>
  request({
    method: 'post',
    url: '/api/privileges/getPrivileges',
    data
  })

export const getPrivilegesCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/privileges/getPrivilegesCount',
    data
  })

export const getPrivilege = (params: any) =>
  request({
    method: 'get',
    url: '/api/privileges/getPrivilege',
    params
  })

export const savePrivilege = (data: any) =>
  request({
    method: 'post',
    url: '/api/privileges/savePrivilege',
    data
  })

export const generatePrivileges = (data: any) =>
  request({
    method: 'post',
    url: '/api/privileges/generatePrivileges',
    data,
    headers:{
      'Content-Type': 'multipart/form-data'
    }
  })

export const savePrivilegeBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/privileges/savePrivilegeBulk',
    data
  })

export const deletePrivilege = (params: any) =>
  request({
    method: 'get',
    url: '/api/privileges/deletePrivilege',
    params
  })

export const deletePrivilegeBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/privileges/deletePrivilegeBulk',
    data
  })
